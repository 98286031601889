<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Alert</h2>
    </template>
    <template #page-content>
      <div>
        <div class="r-card">
          <h3>Overview</h3>
          <p>
            Kotak dialog (Dialog) adalah pop-up sementara yang mengambil fokus
            dari halaman atau aplikasi dan mengharuskan orang untuk berinteraksi
            dengannya. Ini terutama digunakan untuk mengonfirmasi tindakan,
            seperti menghapus file, atau meminta orang untuk membuat pilihan.
          </p>
        </div>
        <r-card>
          <div class="columns is-multiline">
            <div class="column is-full">
              <h4>Default</h4>
              <b-button class="is-primary" outlined @click="confirm()">
                Open
              </b-button>
            </div>
            <div class="column is-full">
              <h4>Default with warning confirmation</h4>
              <b-button class="is-primary" outlined @click="warning()">
                Open
              </b-button>
            </div>
            <div class="column is-full">
              <h4>Success</h4>
              <b-button class="is-primary" outlined @click="success()">
                Open
              </b-button>
            </div>
            <div class="column is-full">
              <h4>Failed</h4>
              <b-button class="is-primary" outlined @click="error()">
                Open
              </b-button>
            </div>
          </div>
        </r-card>
      </div>
    </template>
  </r-page>
</template>
<script>
export default {
  mounted() {
    // console.log('matched', this.$rVar)
  },
  methods: {
    confirm() {
      this.$swal({
        showCloseButton: true,
        showConfirmButton: false,
        titleText: 'Information',
        customClass: {
          confirmButton: 'button is-primary',
        },
        text:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam voluptatibus magni et? Placeat tempore illum praesentium veritatis dolorum totam ipsa iste amet, quasi dolores sed doloribus deleniti delectus rerum quia?',
      })
    },
    warning() {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        input: 'checkbox',
        inputValue: 1,
        inputValidator: (result) => {
          return !result && 'You need to agree with T&C'
        },
        inputPlaceholder: 'I agree with the terms and conditions',
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text:
          'If you processed, you will lose all your Personal Clients. Are you sure you want to delete it?',
      }).then((res) => {
        if (res && res.isConfirmed) {
          this.$swal({
            icon: 'success',
            titleText: 'Thank You',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
        } else if (res && res.isDenied) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
    success() {
      this.$swal({
        icon: 'success',
        titleText: 'Thank You',
        confirmButtonText: 'Done',
        customClass: {
          confirmButton: 'button is-success',
        },
      })
    },
    error() {
      this.$swal({
        icon: 'error',
        titleText: 'Failed',
        confirmButtonText: 'Go Back Home',
        customClass: {
          confirmButton: 'button is-grey',
        },
      })
    },
  },
}
</script>
